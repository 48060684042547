var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"class-body"},_vm._l((_vm.classList),function(item){return _c('div',{key:item.category,class:{
            'class-card':true,
            's-card':_vm.selectClass == item.key_name
        },on:{"click":function($event){return _vm.getClassName(item.key_name)}}},[_vm._v(" "+_vm._s(item.key_name)+" ")])}),0),_c('adContent'),_c('div',{staticClass:"list-title"},[_vm._v(_vm._s(_vm.gameList.title))]),_c('div',{staticClass:"game-list"},_vm._l((_vm.gameList.games),function(item){return _c('div',{key:item.id,class:{
            'game-card':true,
            'm-padding':_vm.isMobile,
            'w-padding':!_vm.isMobile
        },on:{"click":function($event){return _vm.goDetails(item.id)}}},[(!_vm.isMobile)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-l"},[_c('img',{attrs:{"src":item.logo,"alt":""}})]),_c('div',{staticClass:"card-r"},[_c('div',{staticClass:"r-title line-clamp"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"r-syn line-clamp"},[_vm._v(_vm._s(item.synopsis))])])]):_c('div',{staticClass:"m-body"},[_c('img',{attrs:{"src":item.logo,"alt":""}}),(_vm.isMobile)?_c('p',{staticClass:"m-title line-clamp"},[_vm._v(_vm._s(item.title))]):_vm._e(),(_vm.isMobile)?_c('p',{staticClass:"m-syn line-clamp"},[_vm._v(_vm._s(item.synopsis))]):_vm._e()]),_c('div',{staticClass:"card-play"},[_vm._v(" PLAY ")])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }